// IMPORTS
import "./UserPage.css";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ProfileCard from "../../component/TableAdmin/ProfileCard";
import SettingsCard from "../../component/UserCard/UserCard";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from '@mui/material/Snackbar';
// FONTS  
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// STYLE & THEME
const theme = createTheme({
  palette: {
    success: {
      main: '#4caf50', // green  
    },
    warning: {
      main: '#ff9800', // yellow  
    },
    error: {
      main: '#f44336', // red  
    },
  },
});

interface InfoData {
  balance: string;
  bettingChoice: string;
  bettingUnit: string;
  currentRaceDate: string;
  currentRaceNum: string;
  holdStatus: any;
  reloginStatus: any;
  betbotUpStatus: any;
  nextRace: string;
  updatedTime: string;
  role: string;
  displayUsername: string;
}

// APP
export default function UserPage() {
  const [openDialog, setOpenDialog] = useState(false);
  const [text, setText] = useState("");
  const [info, setInfo] = useState<InfoData | null>(null);
  const [snackbarReloginOpen, setSnackbarReloginOpen] = useState(false);
  const [username, setUserName] = useState('');
  const [bettingUnit, setBettingUnit] = useState(0);  
  // Function to update the betting unit  
  const updateBettingUnit = (increment: any) => {  
    setBettingUnit((prevUnit) => {  
      // Define the new betting unit, ensuring it doesn't go below 1  
      const newUnit = prevUnit + increment > 0 ? prevUnit + increment : 1;  
      // Here you could also send the updated betting unit to the server if needed  
      // sendBettingUnitUpdate(newUnit);  
      return newUnit;  
    });  
  };  

  useEffect(() => {
    if (!localStorage.getItem("username")) {
      window.location.href = '/login';
    }
  }, []);

  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username) {
      setUserName(username);
    }
  }, []);


  useEffect(() => {
    const fetchData = () => {
      const username = localStorage.getItem('username');
      fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/user-info', {
      // fetch('http://localhost:8000/api/user-info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          race_date: "current",
        }),
      })
        .then(response => response.json())
        .then(data => {
          setInfo({
            balance: data.payload.balance,
            bettingChoice: data.payload.betting_choice,
            bettingUnit: data.payload.betting_unit,
            currentRaceDate: data.payload.current_race_date,
            currentRaceNum: data.payload.current_race_num,
            holdStatus: data.payload.hold_status,
            reloginStatus: data.payload.relogin_status,
            betbotUpStatus: data.payload.betbot_up_status,
            nextRace: data.payload.next_race,
            role: data.payload.role,
            displayUsername: data.payload.display_name,
            updatedTime: new Date().toLocaleTimeString(),
          });
        })
        .catch(error => console.error('Error:', error));

    };

    // Fetch data immediately  
    fetchData();

    // Fetch data every 10 seconds  
    const intervalId = setInterval(fetchData, 5000);

    // Cleanup on unmount  
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (info && parseInt(info.bettingUnit) !== 0 && bettingUnit === 0) {
      setBettingUnit(parseInt(info.bettingUnit));
    }
    if (info && info.holdStatus && info.holdStatus.request) {
      setOpenDialog(true);
    }
  }, [info])
  const mainUser = {
    // DEFAULT VALUES
    unit: info?.bettingUnit ? info?.bettingUnit : "-",
    displayUsername: info?.displayUsername ? info?.displayUsername : "-",
    role: info?.role ? info?.role : "-",
    userName: username,
    accountBalance: info?.balance ? info?.balance : "-",
    bettingChoice: info?.bettingChoice ? (info?.bettingChoice === "Auto-bet" ? "Auto" : (info?.bettingChoice === "Not-to-bet" ? "No" : "Hold")) : "-",
    nextRace: info?.nextRace ? info?.nextRace : "-",
    currentRaceDate: info?.currentRaceDate,
    currentRaceNum: info?.currentRaceNum,
    holdStatus: info?.holdStatus,
    reloginStatus: info?.reloginStatus,
  };
  const handleBet = async (betting_status: string) => {
    const username = localStorage.getItem('username');
    const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/update-betting-status', {
    // const response = await fetch('http://localhost:8000/api/update-betting-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        betting_status: betting_status,
        betting_unit: bettingUnit,
        race_date: info?.currentRaceDate,
        race_num: info?.currentRaceNum,
      }),
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
      })
      .catch(error => console.error('Error:', error));
    setOpenDialog(false);
  };
  const handleReloginClick = async () => {
    const username = localStorage.getItem('username');
    const response = await fetch('https://api-ihr.agileexlab.net/ihr-luck/v4/api/relogin', {
      // const response = await fetch('http://localhost:8000/api/update-betting-choice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username
      }),
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
      })
      .catch(error => console.error('Error:', error));
    setSnackbarReloginOpen(true);
    setTimeout(() => setSnackbarReloginOpen(false), 3000);
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Place Your Bet"}</DialogTitle>
        <DialogContent>  
          <DialogContentText id="alert-dialog-description">  
            Race Date: {info && info.currentRaceDate}  
            <br />  
            Race Number: {info && info.currentRaceNum}  
            <br />  
            Strategy: {info && info.holdStatus && info.holdStatus.betting_decision}  
            <br />  
            Commands:  
            <br />  
            {info && info.holdStatus && info.holdStatus.command && Array.isArray(info.holdStatus.command)  
              ? info.holdStatus.command.map((cmd: any, index: any) => (  
                  <React.Fragment key={index}>  
                    - {cmd}  
                    <br />  
                  </React.Fragment>  
                ))  
              : info?.holdStatus.command}  
            Betting Unit:  
            <Box display="flex" alignItems="center">  
              <IconButton onClick={() => updateBettingUnit(-1)}>-</IconButton>  
              <TextField  
                id="betting-unit-input"  
                type="number"  
                value={bettingUnit}  
                onChange={(e) => setBettingUnit(Number(e.target.value))}  
                inputProps={{  
                  style: { textAlign: 'center' },  
                  'aria-label': 'Betting unit'  
                }}  
              />  
              <IconButton onClick={() => updateBettingUnit(1)}>+</IconButton>  
            </Box>  
          </DialogContentText>  
        </DialogContent>  

        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{ backgroundColor: '#4caf50', color: '#fff' }}
            sx={{ marginRight: 1 }}
            onClick={() => { handleBet("bet") }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{ backgroundColor: '#f44336', color: '#fff' }}
            sx={{ marginRight: 1 }}
            onClick={() => { handleBet("decline") }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <CssBaseline>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2, mx: 2 }}>
          <Grid item xs={4} sm={3} md={2}>
            <IconButton onClick={() => window.location.href = '/user'}>
              <HomeIcon fontSize="large" />
            </IconButton>
          </Grid>
          {/* <Grid item xs={4} sm={3} md={2}>
          </Grid> */}
          <Grid item xs={4} sm={3} md={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReloginClick}
              sx={{ margin: '10px' }}
            >
              Re-login-JC
            </Button>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ExitToAppIcon />}
              onClick={() => window.location.href = '/logout'}>
              Logout
            </Button>
          </Grid>
        </Grid>


        {/* BACKGROUND */}
        <Grid container direction="column" sx={{ overflowX: "hidden" }}>
          <Grid
            container
            direction={{ md: "row" }}
            spacing={3}
            sx={{ px: { xs: 2, md: 7 }, py: { xs: 2, md: 0 } }}
          >
            <Grid item xs={12} md={12}>
              <ProfileCard
                name={mainUser.displayUsername}
                unit={mainUser.unit}
                userName={mainUser.userName}
                accountBalance={mainUser.accountBalance}
                currentRaceDate={mainUser.currentRaceDate}
                currentRaceNum={mainUser.currentRaceNum}
                bettingChoice={mainUser.bettingChoice}
                nextRace={mainUser.nextRace}
                reloginStatus={mainUser.reloginStatus}
              ></ProfileCard>
            </Grid>

            <Grid item xs={12} md={12} style={{ height: "1200px" }}>
              <SettingsCard
                expose={(v: string) => setText(v)}
                displayUsername={mainUser.displayUsername}
                role={mainUser.role}
                userName={mainUser.userName}
                unit={mainUser.unit}
              ></SettingsCard>
            </Grid>
          </Grid>
        </Grid>
      </CssBaseline>
      <Snackbar
        open={snackbarReloginOpen}
        message="Triggered Relogin"
        autoHideDuration={3000}
        onClose={() => setSnackbarReloginOpen(false)}
      />
    </ThemeProvider>
  );
}
